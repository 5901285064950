import { Navbar, Container, Button } from "react-bootstrap";
import {
    NavLink, BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import Users from "../Components/Users";
import Dashboard from "../Components/Dashboard"
import Offdays from "./Offday";
import MoneySlot from "./MoneySlot";
import SixDigits from "./SixDigits";
import SixOffdays from "./SixDigitClose";
import Nav from 'react-bootstrap/Nav';
import Messages from "./Messages";
import ThreeD from "./ThreeD";
import CloseDay from "./CloseDay";

function NavBars() {

    const handleClick = () => {

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('uid');

        window.location.href = "/";
    }

    // const navLinkStyles = ({isActive}) => {
    //     return {
    //         fontWeight : isActive ? "bold" : "normal",
    //     }
    // }

    return (
        <Router>
            <Navbar collapseOnSelect className="color-nav" expand="lg">
                <Container>
                    <Navbar.Brand href="/dashboard" className="text-light font-weight-bold">Thai Myanmar 2D</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="navbarScroll" data-toggle="collapse" />
                    <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                        <Nav>
                            <NavLink className="btn text-light navColor" as={Link} to="/dashboard">Dashboard</NavLink>
                            
                            
                            <NavLink className="btn text-light navColor" as={Link} to="/threed">Three D</NavLink>
                            <NavLink className="btn text-light navColor" as={Link} to="/close_day">Close Day</NavLink>
                            {/* <NavLink className="btn text-light navColor" as={Link} to="/six_digits_close">ခြောက်ကောင်ဂျင် ပိတ်ရက်</NavLink> */}
                            <NavLink className="btn text-light navColor" as={Link} to="/users">Users</NavLink>
                            <NavLink className="btn text-light navColor" as={Link} to="/message">Message</NavLink>
                            
                            <Button onClick={handleClick} className="btn btn-warning text-light">
                                Logout
                            </Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Switch>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/threed">
                    <ThreeD />
                </Route>
                <Route path="/close_day">
                    <CloseDay />
                </Route>
                <Route path="/users">
                    <Users />
                </Route>
                <Route path="/message">
                    <Messages />
                </Route>
                {/* <Route path="/open_day">
                    <Offdays />
                </Route> */}

            </Switch>
        </Router>
    );
}

export default NavBars;