import React from "react";
import "../App.css";
// import { firestore } from "../firebase";
// import { addDoc, collection } from "@firebase/firestore";

import { useState, useEffect } from "react";
import Axios from "axios";
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import { Table, Card, Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import DeleteConfirmation from "../Components/DeleteConfirmation";

const initialState = {
  nname: ""
  //dayname: moment().format("YYYY-MM-DD")
};

function CloseDay(props) {

  const [dayname, setDayName] = useState(moment().format("YYYY-MM-DD")); 

  const [{ nname }, setState] = useState(initialState);

  // const [details, setDetails] = useState({
  //   selectedNo: "",
  //   number1: "",
  //   number2: "",
  // });

  // let oncount = new Date().toLocaleDateString('en-GB', {
  //   day: '2-digit',
  //   month: 'short',
  //   year: 'numeric'
  // }).split(' ').join('-');

  let oncount = moment().format("DD-MMM-YYYY");
  // let dayy = formatDate(dayname);


  const [datas, setDatas] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [status, setStatus] = useState("");
  const [updateId, setUpdateId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  //let [formatDateValue, setFormatDateValue] = useState("");
 
  //const [dayname, setDayName] = useState("");
  function formatDate(date) {
    let oncount = moment(date).format("DD-MMM-YYYY");
    return oncount;
  }


  const showDeleteModal = (id) => {
    console.log(id);
    console.log(sessionStorage.getItem("token"));
    setStatus("");
    setDeleteMessage(`Are you sure you want to delete?`);
    setUpdateId(id);
    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the actual deletion of the item
  const submitDelete = () => {
    console.log(updateId);
    setDisplayConfirmationModal(false);
    let oncount = moment().format("DD-MMM-YYYY");
    Axios.delete(
      `https://thai2d.123spider.com/api/deleteCloseDay/` + updateId,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.status);
        //setDatas(res.data.data);
        if (res.status === 200) {
          Axios.get(
            `https://thai2d.123spider.com/api/getCloseDay`,

            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
            .then((res) => {
              // setStatus("Success")
              // setTimeout(() => {
              //   setStatus("");
              // }, 5000);
              console.log(res.status);
              setDatas(res.data);
            })

            .catch((err) => {
              console.log(err.message);
            });
        }
      })

      .catch((err) => {
        console.log(err.message);
      });
  };

  const clearState = () => {
    setState({ ...initialState });
  };

  const handleSubmits = async (e) => {
    e.preventDefault();
    let dayy = formatDate(dayname);
    // console.log(da)
    // console.log(dayname);

  }
  const handleSubmit = async (e) => {
    e.preventDefault();



    // let oncount = new Date().toLocaleDateString('en-GB', {
    //   day: '2-digit',
    //   month: 'short',
    //   year: 'numeric'
    // }).split(' ').join('-');
    // let oncount = formatDate(dayname);
    // console.log(oncount);
    //let fdate = formatDate(dayname);
    //let oncount = "15-Apr-2022";
    ////const ref = collection(firestore, "nn");
    //const refAll = collection(firestore, "all");

    // addDoc(ref,dat).then(
    //   (e)=>{
    //     console.log(e);
    //     //window.location.href = "/dashboard";
    //   }
    // );
    //console.log(oncount);
    //console.log(formatDateValue);
    //let oncount = moment().format("DD-MMM-YYYY");
    let oncount = formatDate(dayname);
    
    let name = nname;
    Axios.post(
      "https://thai2d.123spider.com/api/storeCloseDay",
      {
        oncount,
        name
        // set50,
        // set100,
        // lucky
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    ).then((res) => {
      //console.log(res);

      // addDoc(ref,dat).then(
      //   (e)=>{
      //     console.log(e);
      //     //window.location.href = "/dashboard";
      //   }
      // );

      //window.location.href = "/dashboard";
      // if(res.status === 200){

      Axios.get(
        `https://thai2d.123spider.com/api/getCloseDay`,

        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => {

          setState(initialState);
          setDatas([]);
          setStatus("Success!");
          setDatas(res.data);
          setTimeout(() => {
            setStatus("");
          }, 5000);
          //clearState()
          //window.location.href = "/dashboard";
          // addDoc(ref, {
          //   "createdAt": Date(),
          //   "date": oncount,
          //   "time": ampm,
          //   "valOne": lucky2,
          //   "valTwo": lucky3,
          // }).then(
          //   (e) => {
          //     //console.log(e);
              
          //   }
          // ).catch((err) => {
          //   window.location.href = "/dashboard";
          // });


        })

      //     .catch((err) => {
      //       console.log(err.message);
      //     });
      // }


    }).catch((err) => {
      console.log(err.message);
    });
  };

  const project = (datas) => {
    switch (datas) {

      case "12": return "12 PM";
      // case "1": return "1 PM";
      case "4": return "4:10 PM";
      case "5": return "5:03 PM";
      // case "7": return "7 PM";
      // case "9": return "9 PM";


      default: return datas
    }
  }

  useEffect(() => {
    //setFormatDateValue(formatDate(dayname));
    //let oncount = "15-Apr-2022";
    // let oncount = new Date().toLocaleDateString('en-GB', {
    //   day: '2-digit',
    //   month: 'short',
    //   year: 'numeric'
    // }).split(' ').join('-');
    //console.log(dayname);
    let oncount = moment().format("DD-MMM-YYYY");
    console.log("data");
    console.log(formatDate(dayname));
   // setState((prevState) => ({ ...prevState, dayname: oncount }))



    Axios.get(
      `https://thai2d.123spider.com/api/getCloseDay`,
      //`https://laos2d.123spider.com/api/admin/getLucky?oncount=${formatDate(dayname)}`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        console.log(res.data);
        setDatas(res.data);
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  //let ot = ;
  //console.log(ot);
  //console.log(datas.oncount)
  return (
    
    <>
      {/* <Navbar /> */}

      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-3">
            <Card>
              <Card.Body>
                {/* <h6 className="mb-3">Date  {oncount}</h6> */}



                <Form onSubmit={handleSubmit}>

                {/* <div className="row">
                    <div>
                      <Form.Group>
                        <Form.Label>Change Date</Form.Label>
                        <Form.Control type="date" required value={dayname} onChange={(e) =>
                    //setDetails({ ...details, selectedNo: e.target.value })
                    {
                      //const { name, value } = e.target;
                      //console.log(e.target.value);
                      //console.log(formatDate(e.target.value));
                      //setFormatDateValue(formatDate(e.target.value));
                      //console.log(formatDateValue);
                      setState((prevState) => ({ ...prevState, dayname: e.target.value }))
                      //console.log(dayname)
                      setDatas([]);
                      console.log(dayname);
                      Axios.get(
                        `https://newyork.black-spider.online/api/admin/getLucky?oncount=${formatDate(e.target.value)}`,
                  
                        {
                          headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                          },
                        }
                      )
                        .then((res) => {
                          //console.log(res.data.data);
                          setDatas(res.data.data.lucky);
                        })
                  
                        .catch((err) => {
                          console.log(err.message);
                        });

                    }
                    } name="dayname" placeholder={"Date"} />
                      </Form.Group>
                    </div>
                  </div> */}
                   <Form.Group>
                   <Form.Label>Name</Form.Label>
                      <Form.Control
                        maxLength={60}
                        type="text"
                        placeholder="Name"
                        value={nname}
                        onChange={(e) => {
                          if (e.target.value.length > 60) {
                            console.log("limit")
                          } else {
                            setState((prevState) => ({ ...prevState, nname: e.target.value }))
                            //setDetails({ ...details, number2: e.target.value })
                          }

                        }

                        }
                      />
                   </Form.Group>
                  
                   <br className="mt-2"></br>
                  <Form.Group>
                        <Form.Label>Select Date</Form.Label>
                        <Form.Control type="date" required value={dayname} onChange={(e) =>{
                          setDayName(e.target.value);
                          
                        }}
                        name="dayname" placeholder={"Date"} />
                   </Form.Group>     
                  <br />
                  {/* <Form.Select
                    aria-label="Default select example"
                    value={ampm}
                    onChange={(e) =>
                    //setDetails({ ...details, selectedNo: e.target.value })
                    {
                      //const { name, value } = e.target;
                      setState((prevState) => ({ ...prevState, ampm: e.target.value }))
                    }
                    }
                  >
                    <option>Select Time</option>
                    <option value="12">12 PM</option>
                    <option value="4">4:10 PM</option>
                    <option value="5">5:03 PM</option>

                  </Form.Select> */}

                  

                  <Row>
                    {/* <Col>
                      <Form.Control
                        maxLength={8}
                        type="text"
                        value={set50}
                        placeholder="Set50"
                        onChange={(e) => {
                          if (e.target.value.length > 8) {
                            console.log("limit")
                          } else {
                            setState((prevState) => ({ ...prevState, set50: e.target.value }))
                            //setDetails({ ...details, number1: e.target.value })
                          }

                        }

                        }
                      />
                    </Col> */}
                    
                    {/* <Col>
                      <Form.Control
                        maxLength={1}
                        type="text"
                        placeholder="Three"
                        value={third_d}
                        onChange={(e) => {
                          if (e.target.value.length > 1) {
                            console.log("limit")
                          } else {
                            setState((prevState) => ({ ...prevState, third_d: e.target.value }))
                            //setDetails({ ...details, number2: e.target.value })
                          }

                        }

                        }
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        maxLength={1}
                        type="text"
                        placeholder="Four"
                        value={fourth_d}
                        onChange={(e) => {
                          if (e.target.value.length > 1) {
                            console.log("limit")
                          } else {
                            setState((prevState) => ({ ...prevState, fourth_d: e.target.value }))
                            //setDetails({ ...details, number2: e.target.value })
                          }

                        }

                        }
                      />
                    </Col> */}
                  </Row>
                  <br className="mt-2"></br>



                  <button type="submit" className="btn btn-warning input-block-level form-control">
                    Add
                  </button>
                </Form>
                {status === "" ? <br className="mt-1"></br> :
                  <>
                    <br className="mt-1"></br>
                    <h6 className="mt-1 text-success">{status}</h6>
                  </>
                }
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-9">
            <Table responsive="sm" striped bordered hover>
              <thead>
                <tr>
                <th>Name</th>
                  <th>Date</th>
                  
                 
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {datas.map((data, i) => (
                  <tr key={i}>
                    <td>{data.name}</td>
                    <td>{data.oncount}</td>
                    

                    <td> <FiTrash2 className="icon"
                        onClick={() => showDeleteModal(data.id)}
                        style={{
                          color: 'red',
                          top: '20px',
                          right: '20px',
                        }}
                        size="25px" /></td>
                  </tr>
                ))}
              
                {/* {datas.map((data, i) => (
                 
                ))} */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} message={deleteMessage} />
    </>
  );
}

export default CloseDay;
