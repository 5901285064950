import React from "react";
import "../App.css";

import { useState, useEffect } from "react";
import Axios from "axios";
import { Card, Form } from "react-bootstrap";
import moment from "moment";


const initialState = {
  message: "",
};

function Messages(props) {

  // let oncount = new Date().toLocaleDateString('en-GB', {
  //     day: '2-digit',
  //     month: 'short',
  //     year: 'numeric'
  // }).split(' ').join('-');  

  let oncount = moment().format("DD-MMM-YYYY");
  const [{ message }, setState] = useState(initialState);
  const [datas, setDatas] = useState("");

  const clearState = () => {
    setState({ ...initialState });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };


  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState("");

  // const [details, setDetails] = useState({ name: "", password: "" });


  const handleSubmit = (e) => {

    e.preventDefault();
    // let oncount = new Date().toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //     month: 'short',
    //     year: 'numeric'
    // }).split(' ').join('-');
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      console.log(oncount);

      Axios.post("https://thai2d.123spider.com/api/storeMessage", {
        message
      }, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            Axios.get(
              `https://thai2d.123spider.com/api/getMessage`,

              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
              }
            )
              .then((res) => {
                setStatus("Success!");
                setValidated(false);
                //setUpdate(false);
                clearState();
                setTimeout(() => {
                  setStatus("");
                }, 5000);

                //setDatas(res.data.data.lucky);
                //console.log(res.data.data)
                //setDatas(res.data.data);
                setDatas(res.data[0].message ?? "");
              })

              .catch((err) => {
                console.log(err.message);
              });
          }


        })
        .catch((err) => {
          console.log(err.message)
        });
      // }

      setValidated(true);



    }

    //   function formatDate(date) {
    //     var d = new Date(date).toLocaleDateString('en-GB', {
    //       day: 'numeric',
    //       month: 'short',
    //       year: 'numeric'
    //   }).split(' ').join('-');
    //     return d;
  }






  //   let uid = sessionStorage.getItem("uid");

  //   const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  //   const [update, setUpdate] = useState(false);
  //   const [updateId, setUpdateId] = useState(null);
  //   const [deleteMessage, setDeleteMessage] = useState(null);

  //   const showDeleteModal = (id) => {
  //     setStatus("");
  //     setDeleteMessage(`Are you sure you want to delete?`);
  //     setUpdateId(id);
  //     setDisplayConfirmationModal(true);
  //   };

  //   // Hide the modal
  //   const hideConfirmationModal = () => {
  //     setDisplayConfirmationModal(false);
  //   };

  //   // Handle the actual deletion of the item
  //   const submitDelete = () => {
  //     console.log(updateId);
  //     setDisplayConfirmationModal(false);
  //     Axios.delete(
  //       `https://etrade.123spider.com/api/admin/deleteUser/`+ updateId,

  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //         },
  //       }
  //     )
  //       .then((res) => {
  //         console.log(res.status);
  //         //setDatas(res.data.data);
  //         if(res.status === 200){
  //           Axios.get(
  //             `https://etrade.123spider.com/api/admin/allUser`,

  //             {
  //               headers: {
  //                 Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //               },
  //             }
  //           )
  //             .then((res) => {
  //               setStatus("Success")
  //               setTimeout(() => {
  //                 setStatus("");
  //               }, 5000);
  //               console.log(res.status);
  //               //setDatas(res.data.data);
  //             })

  //             .catch((err) => {
  //               console.log(err.message);
  //             });
  //         }
  //       })

  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   };



  useEffect(() => {

    Axios.get(
      `https://thai2d.123spider.com/api/getMessage`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        //console.log(res.data[0].message);
        //setDatas(res.data.data.slot_lucky);
        setDatas(res.data[0].message ?? "");
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //  function handleEditClicks (id,names) {
  //         //e.preventDefault();    
  //         setStatus("");
  //         //setUpdate(true);
  //         //console.log(id);  
  //         //console.log(names); 
  //         //setUpdateId(id);
  //         setState((prevState) => ({ ...prevState, name: names }));
  //       }

  //   function handleEditCancel (){
  //     setStatus("");
  //     setUpdate(false);
  //     clearState();
  //   }  

  //   const updateData = (e)=>{
  //     console.log(updateId);
  //     e.preventDefault();
  //     if(updateId != null){
  //       Axios.put(
  //         `https://etrade.123spider.com/api/admin/updateUser/` + updateId,{
  //           name,
  //           password,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //           },
  //         }
  //       ).then((res)=>{
  //         if(res.status === 200){
  //           Axios.get(
  //             `https://etrade.123spider.com/api/admin/allUser`,

  //             {
  //               headers: {
  //                 Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //               },
  //             }
  //           )
  //             .then((res) => {
  //               setStatus("Success!");
  //               setValidated(false);
  //               setUpdate(false);
  //               clearState();
  //               setTimeout(() => {
  //                 setStatus("");
  //               }, 5000);

  //               //setDatas(res.data.data.lucky);
  //               //console.log(res.data.data)
  //               setDatas(res.data.data);
  //             })

  //             .catch((err) => {
  //               console.log(err.message);
  //             });
  //         }

  //       }).catch((err) => {
  //         console.log(err.message)
  //       });

  //}


  return (
    <>
      {/*\\ <Navbar /> */}

      <div className="container mt-3">
        <div className="row">
          <div className="col-lg-4">
            <Card>
              <Card.Body>
                <h6 className="mb-4">Date {oncount}</h6>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                  <Form.Group controlId="validationCustom03">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your message"
                      required
                      value={message}
                      name="message"
                      onChange={onChange}
                    //onChange={e => setDetails({ ...details, name: e.target.value })}
                    //value={details.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid Value.
                    </Form.Control.Feedback>
                  </Form.Group>


                  <br className="mt-2"></br>
                  <button type="submit" className="btn btn-warning input-block-level form-control">Add</button>


                  <br className="mt-3"></br>

                </Form>
                {status === "" ? <br className="mt-1"></br> :
                  <>
                    <br className="mt-1"></br>
                    <h6 className="mt-1 text-success">{status}</h6>
                  </>
                }

              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-8">
            <Card>
              <Card.Body>
                <h6>{datas}</h6>
              </Card.Body>
            </Card>
          </div>


        </div>
      </div>
      {/* <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={submitDelete} hideModal={hideConfirmationModal} message={deleteMessage}  />
     */}

    </>
  );
}

export default Messages;
